export const exportCSVFile = (responseBody) => {
  const jsonData = responseBody.map((row) => ({
    Name: row.ruleName,
    Type: row.ruleType,
    Description: row.description,
    Rules:
      row.ruleExpression !== null ? row.ruleExpression : row.dependentRules,
    Model: row.models !== null ? row.models : '',
    Severity: row.severity,
    Enable:
      row.isDeleted !== null && row.isDeleted.toString() === 'false'
        ? 'Enable'
        : 'Disable',
    'Trigger Count':
      row.alarmTriggerCount !== '0' ? row.alarmTriggerCount : 'N/A',
    'Window Period':
      row.alarmTriggerWindowPeriod !== '0'
        ? row.alarmTriggerWindowPeriod
        : 'N/A',
	CreateDate : row.createTime !== null ? row.createTime : 'N/A',
    UpdateDate : row.updateTime !== null ? row.updateTime : 'N/A'
  }));

  if (jsonData.length > 0) {
    const csvData = objectToCsv(jsonData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'Rules_Data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.alert('There is no data to export');
  }
};

export const objectToCsv = (jsonData) => {
  const csvRows = [];
  //get the Headers
  const headers = Object.keys(jsonData[0]);
  csvRows.push(headers.join(','));
  //loop over the rows
  for (const row of jsonData) {
    const values = headers.map((header) => {
      const escaped = ('' + row[header]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
};
