import { combineReducers } from 'redux';
import LoginReducer from './AuthReducer';
import assetListReducer from './AssetListReducer';
import alarmsReportListReducer from './AlarmsReportListReducer';
import rulesListreducer from './RulesListReducer';
import onBoardingReducer from './OnBoardingReducer';
import ReportsListReducer from './ReportsListReducer';
import RuleImportFileReducer from './RuleImportFileReducer';
import UploadFileReducer from './UploadFileReducer';
import ModelsListReducer from './ModelsListReducer';
import ContentsListReducer from './ContentsListReducer';
import UsersListReducer from './UsersListReducer';
import { reducer as form } from 'redux-form';

const rootReducer = combineReducers({
  LoginReducer,
  assetListReducer,
  alarmsReportListReducer,
  rulesListreducer,
  RuleImportFileReducer,
  ModelsListReducer,
  ContentsListReducer,
  onBoardingReducer,
  ReportsListReducer,
  UploadFileReducer,
  UsersListReducer,
  form,
});

export default rootReducer;
