import { connect } from "react-redux";
import { compose, withHandlers, lifecycle } from "recompose";
import { reduxForm } from "redux-form";
import {
  saveModelsDataToStore,
  resetModelsPage,
  modelsNextPageRoute,
  modelCancelButtonIsClicked,
  fetchModelsData,
  fecthModelDeviceParams,
  fecthExcelModelDeviceParams,
  addAttributesToAttributesCheckedList,
  removeAttributesToAttributesCheckedList,
  addModelListOnUpdate,
  removeModelListOnUpdate,
  addPageSize,
  removePageSize,
  updateModel,
} from "../../../redux/reducers/ModelsListReducer";
import { modelsListPagePath } from "./../../../utilities/pathHelpers";
import { withRouter } from "react-router";
import { exportCSVFile, exportCSVModelFile } from "./../../../apis/modelsListAPI/normalizers";

export const mapStateToProps = (state) => ({
  pageSize: state.ModelsListReducer.pageSize,
  modelsChekedLists: state.ModelsListReducer.modelParamsOnUpdate,
  isFetching: state.ModelsListReducer.isFetchingModels,
  isFetchingDeviceParams: state.ModelsListReducer.isFetchingDeviceParams,
  modelsData: state.ModelsListReducer.modelData,
  modelsDeviceParams: state.ModelsListReducer.modelDeviceParams
    ? state.ModelsListReducer.modelDeviceParams
    : [],
  modelsCheckedLists: state.ModelsListReducer.modelsCheckedLists
    ? state.ModelsListReducer.modelsCheckedLists.attributes
    : [],
  initialValues: {
    modelName: state.ModelsListReducer.modelData.modelName
      ? state.ModelsListReducer.modelData.modelName || ""
      : "",
    description: state.ModelsListReducer.modelData.modelDesc
      ? state.ModelsListReducer.modelData.modelDesc || ""
      : "",
  },
});

export function componentDidMount() {
  this.props.fetchModelsData(this.props.match.params.id);
  this.props.fecthModelDeviceParams(this.props.match.params.id);
}

export const fetchUpdateParams = ({ fecthExcelModelDeviceParams, initialValues }) => async (
  values
) => {
  fecthExcelModelDeviceParams(initialValues.modelName.toUpperCase());
};

export function componentWillUnmount() {
  modelCancelButtonIsClicked();
  this.props.resetModelsPage();
}

export const onSubmit = ({ updateModel, history }) => async (values) => {
  updateModel(values).then((payload) => {
    if (payload.type === "UPDATE_MODEL_DATA_REQUEST_SUCCESS") {
      window.alert(payload.payload.message);
      history.push(modelsListPagePath);
    } else {
      window.alert(payload.payload.message);
    }
  });
};

export const updateToggleRow = ({
  addAttributesToAttributesCheckedList,
  removeAttributesToAttributesCheckedList,
  addModelListOnUpdate,
  removeModelListOnUpdate,
}) => (event, mnemonic, description, displayName, dataType, unitsOfMeasure) => {
  let selectAllCheckbox = document.getElementsByName("selectAll");
  const val = event.target.checked;
  let assignList = {
    [mnemonic]: {
      displayName: displayName !== undefined ? displayName : "",
      dataType: dataType !== undefined ? dataType : "",
      mnemonic: mnemonic,
      description: description !== undefined ? description : "",
      unitsOfMeasure: unitsOfMeasure !== undefined ? unitsOfMeasure : "",
    },
  };
  if (val) {
    addModelListOnUpdate(mnemonic);
    addAttributesToAttributesCheckedList(assignList);
    selectAllCheckbox.disabled = true;
  } else {
    removeModelListOnUpdate(mnemonic);
    removeAttributesToAttributesCheckedList(assignList);
  }
};

export const updateToggleSelectAll = ({
  addAttributesToAttributesCheckedList,
  removeAttributesToAttributesCheckedList,
  addModelListOnUpdate,
  removeModelListOnUpdate,
  addPageSize,
  removePageSize,
}) => (event, page) => {
  if (page === "") {
    page = 0;
  } else {
    page = page + 1;
  }
  if (document.getElementsByName("selectAll")[0].checked === true) {
    addPageSize(page);
  } else {
    removePageSize(page);
  }
  let checkboxes = document.getElementsByName("deviceParams");
  let assignList = {};
  var splitedCheckboxValues;
  if (event.target.checked) {
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = true;

      splitedCheckboxValues = checkboxes[i].value.split(",");
      assignList = {
        [splitedCheckboxValues[0]]: {
          displayName:
            splitedCheckboxValues[2] !== undefined
              ? splitedCheckboxValues[2]
              : "",
          dataType:
            splitedCheckboxValues[3] !== undefined
              ? splitedCheckboxValues[3]
              : "",
          mnemonic:
            splitedCheckboxValues[0] !== undefined
              ? splitedCheckboxValues[0]
              : "",
          description:
            splitedCheckboxValues[1] !== undefined
              ? splitedCheckboxValues[1]
              : "",
          unitsOfMeasure:
            splitedCheckboxValues[4] !== undefined
              ? splitedCheckboxValues[4]
              : "",
        },
      };
      addModelListOnUpdate(splitedCheckboxValues[0]);
      addAttributesToAttributesCheckedList(assignList);
      //checkboxes.selected = false;
    }
  } else {
    for (i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
      splitedCheckboxValues = checkboxes[i].value.split(",");
      assignList = {
        [splitedCheckboxValues[0]]: {
          displayName:
            splitedCheckboxValues[2] !== undefined
              ? splitedCheckboxValues[2]
              : "",
          dataType:
            splitedCheckboxValues[3] !== undefined
              ? splitedCheckboxValues[3]
              : "",
          mnemonic:
            splitedCheckboxValues[0] !== undefined
              ? splitedCheckboxValues[0]
              : "",
          description:
            splitedCheckboxValues[1] !== undefined
              ? splitedCheckboxValues[1]
              : "",
          unitsOfMeasure:
            splitedCheckboxValues[4] !== undefined
              ? splitedCheckboxValues[4]
              : "",
        },
      };
      //checkboxes.selected = false;
      removeModelListOnUpdate(splitedCheckboxValues[0]);
      removeAttributesToAttributesCheckedList(assignList);
    }
  }
};

export const backToListPage = ({ history }) => () => {
  history.push(modelsListPagePath);
};

export const btnHandler = ({modelsDeviceParams,modelsChekedLists,modelsData}) => {
  exportCSVModelFile(modelsData,modelsChekedLists);
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    saveModelsDataToStore,
    modelsNextPageRoute,
    modelCancelButtonIsClicked,
    fetchModelsData,
    fecthModelDeviceParams,
    fecthExcelModelDeviceParams,
    resetModelsPage,
    addAttributesToAttributesCheckedList,
    removeAttributesToAttributesCheckedList,
    addModelListOnUpdate,
    removeModelListOnUpdate,
    addPageSize,
    removePageSize,
    updateModel,
  }),
  //withHandlers({ onPageChange }),
  withHandlers({
    onSubmit,
    fetchUpdateParams,
    updateToggleRow,
    updateToggleSelectAll,
    backToListPage,
    btnHandler,
  }),
  lifecycle({ componentDidMount, componentWillUnmount }),

  reduxForm({
    form: "UpdateModel",
    enableReinitialize: true,
  })
);
