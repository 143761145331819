import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers } from 'recompose';
import {
  fetchAssetDetails,
  fetchAlarmDetails,
  fetchRulesDetails,
  fetchWorkstationDetails,
  fetchMassSpecDetails,
  fetchLCDataDetails,
  fetchErrorDataDetails,
  fetchSampleQueueListDetails,
  fetchDeviceParameters,
  detailsPageButtonIsClicked,
  historicalDataitemsPageIsClicked,
  detailsPageBackButtonIsClicked,
  resetAssetDetailsPage,
  fetchAssetsLists,
} from '../../../../redux/reducers/AssetListReducer';
import $ from 'jquery';

/**
 *
 * @description {get values from store}
 */
export const mapStateToProps = (state) => ({
  assetDetailsdata: state.assetListReducer.assetDetailsdata,
  isFetchingAssets: state.assetListReducer.isFetchingAssets,
  isFetchingAlarmsData: state.assetListReducer.isFetchingAlarmsData,
  isFetchingRulesData: state.assetListReducer.isFetchingRulesData,
  isFetchingWorkstationData: state.assetListReducer.isFetchingWorkstationData,
  isFetchingMassspecData: state.assetListReducer.isFetchingMassspecData,
  isFetchingLCData: state.assetListReducer.isFetchingLCData,
  isFetchingErrorData: state.assetListReducer.isFetchingErrorData,
  isFetchingSampleQueueData: state.assetListReducer.isFetchingSampleQueueData,
  alarmDetailsdata: state.assetListReducer.alarmDetailsdata,
  rulesDetailsdata: state.assetListReducer.rulesDetailsdata,
  workstationDetailsData: state.assetListReducer.workstationDetailsData,
  massSpecDetailsData: state.assetListReducer.massSpecDetailsData,
  lcDataDetailsData: state.assetListReducer.lcDataDetailsData,
  errorDataDetailsData: state.assetListReducer.errorDataDetailsData,
  sampleQueueListDetailsData: state.assetListReducer.sampleQueueListDetailsData,
  detailsPageStatus: state.assetListReducer.detailsPageStatus,
  tableType: state.assetListReducer.tableType,
  deviceParameters: state.assetListReducer.assetsDeviceParameters
    ? state.assetListReducer.assetsDeviceParameters
    : [],
});

/**
 *
 * @description {get details page}
 */
export const isDetailsPageButtonIsClicked = ({
  detailsPageButtonIsClicked,
}) => (type) => {
  detailsPageButtonIsClicked(type);
};

export const isHistoricalDataitemsPageIsClicked = ({
  historicalDataitemsPageIsClicked,
}) => (type) => {
  historicalDataitemsPageIsClicked(type);
};

/* istanbul ignore next */
export const handleSubmit = () => () => {
  window.location.reload();
};

/**
 *
 * @description {remove/unmount component values}
 */
export function componentWillUnmount() {
  this.props.resetAssetDetailsPage();
  this.props.fetchAssetsLists();
}

/**
 *
 * @description {onpage load API calls}
 */
export function componentDidMount() {
  let urlPathName = window.location.pathname;
  let assetDetailRoute = urlPathName.split('/');
  if (assetDetailRoute[1] === 'assets') {
    $(
      '.navbarstyle .menu div.dropdownmenuHighlight.onboardingActive'
    ).removeClass('active');
    $('.navbarstyle .menu a.item')
      .first()
      .addClass('active');
    $('.navbarstyle .menu a.item')
      .first()
      .siblings()
      .removeClass('active');
  }
  this.props.fetchAssetDetails(this.props.match.params.id);
  this.props.fetchAlarmDetails(this.props.match.params.id);
  this.props.fetchRulesDetails(
    this.props.match.params.model.replace(/\s/g, '').toUpperCase()
  );
  this.props.fetchWorkstationDetails(this.props.match.params.id);
  this.props.fetchMassSpecDetails(this.props.match.params.id);
  this.props.fetchLCDataDetails(this.props.match.params.id);
  this.props.fetchErrorDataDetails(this.props.match.params.id);
  this.props.fetchSampleQueueListDetails(this.props.match.params.id);
  //this.props.fetchDeviceParameters('onload');
  this.props.fetchDeviceParameters(this.props.match.params.model.replace(/\s/g, '').toUpperCase());
}

export default compose(
  connect(mapStateToProps, {
    fetchAssetDetails,
    fetchAlarmDetails,
    fetchRulesDetails,
    fetchWorkstationDetails,
    fetchMassSpecDetails,
    fetchLCDataDetails,
    fetchErrorDataDetails,
    fetchSampleQueueListDetails,
    fetchDeviceParameters,
    detailsPageButtonIsClicked,
    historicalDataitemsPageIsClicked,
    detailsPageBackButtonIsClicked,
    resetAssetDetailsPage,
    fetchAssetsLists,
  }),
  withHandlers({
    isDetailsPageButtonIsClicked,
    isHistoricalDataitemsPageIsClicked,
    handleSubmit,
  }),
  lifecycle({ componentDidMount, componentWillUnmount })
);
