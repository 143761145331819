import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import {
  detailsPageBackButtonIsClicked,
  fetchHistoryData,
  historyDataIsClicked,
} from '../../../../redux/reducers/AssetListReducer';
import { withRouter } from 'react-router';
import { exportCSVForMnemonicsData } from '../../../../apis/assetListAPI/normalizers';
import { fetchMnemonicsData } from '../../../../redux/reducers/AssetListReducer';

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  tableType: state.assetListReducer.tableType,
  historyDetailsData: state.assetListReducer.historyDetailsData,
});

/**
 *
 * @description {back button calls}
 */
export const isDetailsPageBackButtonIsClicked = ({
  detailsPageBackButtonIsClicked,
}) => () => {
  detailsPageBackButtonIsClicked();
};

export const getMnemonicsData = ({ fetchMnemonicsData }) => (filteredData) => {
  fetchMnemonicsData(filteredData);
};

export const exportFile = ({ tableType }) => {
  exportCSVForMnemonicsData(tableType);
};

/**
 *
 * @description {click history data calls}
 */
export const isHistoryDataClicked = ({ historyDataIsClicked }) => (type) => {
  historyDataIsClicked(type);
};

/**
 *
 * @description {fetch history data calls}
 */
export const getHistoryData = ({ fetchHistoryData }) => (values) => {
  fetchHistoryData(values);
};

export const handleSubmit = () => () => {
  window.location.reload(true);
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    detailsPageBackButtonIsClicked,
    historyDataIsClicked,
    fetchHistoryData,
    fetchMnemonicsData,
  }),
  withHandlers({
    isDetailsPageBackButtonIsClicked,
    isHistoryDataClicked,
    getHistoryData,
    exportFile,
    getMnemonicsData,
    handleSubmit,
  })
);
