import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import {
  fetchRulesLists,
  fetchRulesData
} from "../../../redux/reducers/RulesListReducer";
import { exportCSVFile } from "./../../../apis/rulestListAPI/normalizers";
import {rulesImportFilePath} from "./../../../utilities/pathHelpers";

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = state => ({
  rulesListsdata: state.rulesListreducer.rulesListsdata,
  filteredData: state.rulesListreducer.rulesFilteredData
});
/**
 *
 * @description {onpage load call API}
 */
export function componentDidMount(prevProps) {
  this.props.fetchRulesLists();
}

export const routeChange = ({ history }) => () =>{ 
  //let path = "/rules-import-file"; 
  history.push(rulesImportFilePath);
};
/**
 *
 * @description {export button call}
 */
// export const btnHandler = ({ fetchRulesData }) => () => {
//   fetchRulesData();
// };
/* istanbul ignore next */
export const btnHandler = ({ filteredData }) => () => {
  exportCSVFile(filteredData);
};

export default compose(
  connect(mapStateToProps, { fetchRulesLists, fetchRulesData }),
  withHandlers({ btnHandler, routeChange }),
  lifecycle({ componentDidMount })
);
