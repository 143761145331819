export default {
  //Login
  login_url:
    'https://dev.platform.usermanagement.sciexstatusscope.com/v2/user_management/login_user',
  forgot_password_url:
    'https://dev.platform.usermanagement.sciexstatusscope.com/v2/user_management/send_verification_code/',
  change_password_url:
    'https://dev.platform.usermanagement.sciexstatusscope.com/v2/user_management/change_default_password',
  new_password_url:
    'https://dev.platform.usermanagement.sciexstatusscope.com/v2/user_management/forgot_password',

  //Assets
  assets_url:
    'https://dev.platform.deviceprovisioning.sciexstatusscope.com/v2/device_management/device/advancesearch',
  asset_details_url:
    'https://dev.platform.deviceprovisioning.sciexstatusscope.com/v2/device_management/device/',
  get_alarms_report_list_url:
    'https://dev.platform.rules.sciexstatusscope.com/v2/rule_management/getalarms',
  get_alarms_url:
    'https://dev.platform.rules.sciexstatusscope.com/v2/rule_management/getevents',
  get_rules_based_on_modelname:
    'https://dev.platform.rules.sciexstatusscope.com/v2/rule_management/getrulesbasedonmodel?pagesize=2500&offset=1&model=',
  get_assets_grid_data:
    'https://dev.platform.deviceprovisioning.sciexstatusscope.com/v2/device_management/devicedata',
  get_history_data:
    'https://dev.platform.deviceprovisioning.sciexstatusscope.com/v2/device_management/devices',
  get_historical_dataitems:
    // 'https://uk543j1fkl.execute-api.us-west-2.amazonaws.com/Prod/v2/device_management/device/historicaldata',
    'https://dev.platform.deviceprovisioning.sciexstatusscope.com/v2/device_management/device/hystoricaldata',

  //Onboarding
  Update_onboarding_url:
    'https://dev.platform.deviceprovisioning.sciexstatusscope.com/v2/device_management/device/status',
  Remark_onboarding_url:
    'https://dev.platform.deviceprovisioning.sciexstatusscope.com/v2/device_management/device/updateremarks',

  //Rules
  rules_url:
    'https://dev.platform.rules.sciexstatusscope.com/v2/rule_management/getrules',
  create_rules_url:
    'https://dev.platform.rules.sciexstatusscope.com/v2/rule_management/createrule',
  update_rules_url:
    'https://dev.platform.rules.sciexstatusscope.com/v2/rule_management/updaterule',
  get_rules_based_on_rulename:
    'https://dev.platform.rules.sciexstatusscope.com/v2/rule_management/getrulebasedonrulename?ruleName=',
  create_device_code:
    'https://dev.platform.rules.sciexstatusscope.com/v2/rule_management/createdevicecode',
  device_code_list:
    'https://dev.platform.rules.sciexstatusscope.com/v2/rule_management/getdevicecode?pagesize=2500&offset=1',

  //Rules of Rules
  get_list_of_rules_of_rules:
    'https://dev.platform.rules.sciexstatusscope.com/v2/rule_management/getrulebasedonruletype?pagesize=2500&offset=1',
  create_rules_of_rules:
    'https://dev.platform.rules.sciexstatusscope.com/v2/rule_management/createruleOfRules',
  update_rules_of_rules:
    'https://dev.platform.rules.sciexstatusscope.com/v2/rule_management/updateruleofrule',

  //RulesImportFileAPI
  uploadruledata_generatepressignedurl:
    'https://dev.platform.rules.sciexstatusscope.com/v2/rule_management/generatepresignedurl',
  uploadruledata:
    'https://dev.platform.rules.sciexstatusscope.com/v2/rule_management/uploadruledata', 

  //Users
  get_users_url:
    'https://dev.platform.usermanagement.sciexstatusscope.com/v2/user_management/get_users?pagesize=2500&offset=1',
  create_user_url:
    'https://dev.platform.usermanagement.sciexstatusscope.com/v2/user_management/create_user',
  disable_user_url:
    'https://dev.platform.usermanagement.sciexstatusscope.com/v2/user_management/delete_user/',
  force_password_change:
    'https://dev.platform.usermanagement.sciexstatusscope.com/v2/user_management/force_change_password',
  get_user_data:
    'https://dev.platform.usermanagement.sciexstatusscope.com/v2/user_management/get_user/',
  update_user_url:
    'https://dev.platform.usermanagement.sciexstatusscope.com/v2/user_management/update_user',

  //Reports
  reports_get_url:
    'https://dev.platform.reports.sciexstatusscope.com/Prod/getreports?pagesize=2500&offset=1',
  get_report_list_url:
    'https://dev.platform.reports.sciexstatusscope.com/v2/report_management/get_reports',
  get_parameter_list_url:
    'https://dev.platform.reports.sciexstatusscope.com/v2/report_management/get_parameters_list/',
  execute_report_url:
    'https://dev.platform.reports.sciexstatusscope.com/v2/report_management/report_query_execution',
  download_report_url:
    'https://dev.platform.reports.sciexstatusscope.com/v2/report_management/download_report',
  get_report_advance_search_url:
    'https://dev.platform.reports.sciexstatusscope.com/v2/report_management/get_reports',
  get_single_report_url:
    'https://dev.platform.reports.sciexstatusscope.com/v2/report_management/get_report',
  create_report_url:
    'https://dev.platform.reports.sciexstatusscope.com/v2/report_management/create_report',
  update_report_url:
    'https://dev.platform.reports.sciexstatusscope.com/v2/report_management/update_report',

  //Contents
  get_content:
    'https://dev.platform.jobmanagement.sciexstatusscope.com/v2/job_management/get_jobs',
  get_content_jobname:
    'https://dev.platform.jobmanagement.sciexstatusscope.com/v2/job_management/get_job/',
  advance_search_get_content:
    'https://dev.platform.jobmanagement.sciexstatusscope.com/Prod/getjobs',
  create_content_init_url:
    'https://dev.platform.jobmanagement.sciexstatusscope.com/v2/job_management/generatejobpresignedurl',
  create_content_final_url:
    'https://dev.platform.jobmanagement.sciexstatusscope.com/v2/job_management/create_job',
  update_content:
    'https://dev.platform.jobmanagement.sciexstatusscope.com/v2/job_management/update_job',

  //Models
  models_get_url:
    'https://dev.platform.deviceprovisioning.sciexstatusscope.com/v2/model_management/get_models?pagesize=2500&offset=1',
  get_models_data:
    'https://dev.platform.deviceprovisioning.sciexstatusscope.com/v2/model_management/get_modelInformation/',
  models_device_params:
    'https://dev.platform.systemmanagement.sciexstatusscope.com/v2/system_management/get_deviceParameters',
  create_model_url:
    'https://dev.platform.deviceprovisioning.sciexstatusscope.com/v2/model_management/create_model',
  update_model_url:
    'https://dev.platform.deviceprovisioning.sciexstatusscope.com/v2/model_management/update_model',
  delete_model_url:
    'https://dev.platform.deviceprovisioning.sciexstatusscope.com/v2/model_management/delete_model/',

  //FileUpload
  device_params_generatepresignedurl_url:
    'https://dev.platform.systemmanagement.sciexstatusscope.com/v2/system_management/generatePreSignedUrl',
  device_params_upload_device_data_url:
    'https://dev.platform.systemmanagement.sciexstatusscope.com/v2/system_management/uploadDeviceData',

  //Common
  parameter_list_url:
    'https://dev.platform.deviceprovisioning.sciexstatusscope.com/v2/model_management/get_modelparameters',
};
