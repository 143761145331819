export const exportCSVFile = (responseBody) => {
    /*let result=[];
    
    result.push(Object.keys(responseBody));
          
        
    
  const jsonData = responseBody.map((row) => ({
   'Reasons ': row.result
  }));*/
  //const jsonData=responseBody;
  var result=[];
for(var data in responseBody.payload.ruleStatus){
          result.push(data);
          
        
    }
    const jsonData = result.map((row) => ({
    'Rules of ': row,
   'Reason': responseBody.payload.ruleStatus[row]
  }));
  if (jsonData.length !== 0) {
    const csvData = objectToCsv(jsonData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'Rules_Updated_Data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.alert('There is No data to Export');
  }
};

export const objectToCsv = (jsonData) => {
  const csvRows = [];
  //get the Headers
  const headers = Object.keys(jsonData[0]);
  csvRows.push(headers.join(','));
  //loop over the rows
  for (const row of jsonData) {
    const values = headers.map((header) => {
      const escaped = ('' + row[header]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
};
