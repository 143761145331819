import * as rulestListAPI from './rulestListAPI';
import * as modelsListAPI from './modelsListAPI';
import * as assetListAPI from './assetListAPI';
import * as alarmsReportListAPI from './alarmsReportListAPI';
import * as usersListAPI from './usersListAPI';
import * as uploadFileAPI from './uploadFileAPI';
import * as contentsListAPI from './contentsListAPI';
import * as onBoardingAPI from './onBoardingAPI';
import * as reportsListAPI from './reportsListAPI';
import * as loginAPI from './authAPI/loginAPI';
import * as ruleImportFileAPI from './ruleImportFileAPI';
export default {
  loginAPI,
  rulestListAPI,
  modelsListAPI,
  assetListAPI,
  alarmsReportListAPI,
  usersListAPI,
  uploadFileAPI,
  contentsListAPI,
  onBoardingAPI,
  reportsListAPI,
  ruleImportFileAPI,
};
