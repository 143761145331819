import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import logo from './../../../images/Sciex.jpg';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styles from './NavBar.module.scss';
import './NavBar.module.scss';
import $ from 'jquery';
import { NavItems } from '../../../redux/constants/Values';

export class NavigationBar extends Component {
  /* istanbul ignore next */
  componentDidMount() {
    let urlPathName = window.location.pathname.slice(1);
    let updateRoute = urlPathName.split('/'); // For Update Screens
    let routeNameSplit = urlPathName.split('-'); //For all create and list Pages
    let arrayMenuButton = $('.dropdown button').toArray();
    let assetDetailRoute = urlPathName.split('/'); //For Asset-Details Pages
    let assetMenu = 'assets';

    /*Readonly User*/
    $('.menu a.item').on('click', function() {
      $(this).addClass('active');
      $(this)
        .siblings()
        .removeClass('active');
    });

    window.onpopstate = function() {
      let urlPathName2 = window.location.pathname.slice(1);

      if (urlPathName2 === 'deviceOnBoarding') {
        $('.assetsActive').removeClass('active');
        $('a.onboardingActiveReadOnlyUser')
          .siblings()
          .removeClass('active');
        $('a.onboardingActiveReadOnlyUser')
          .siblings()
          .children('a')
          .removeClass('active');
        $('.dropdownmenuHighlight.onboardingActive a.item').addClass('active');
        $('.dropdownmenuHighlight.onboardingActive')
          .siblings()
          .children('a.item')
          .removeClass('active');
        $('.dropdownmenuHighlight.onboardingActive')
          .siblings()
          .removeClass('active');
      } else if (
        urlPathName2 === 'assets' ||
        urlPathName2.split('/')[0] === 'assets'
      ) {
        $('.assetsActive').addClass('active');
        $('.onboardingActiveReadOnlyUser').removeClass('active');
        $('.dropdownmenuHighlight.assetsActive a.item').addClass('active');
        $('.dropdownmenuHighlight.assetsActive')
          .siblings()
          .children('a.item')
          .removeClass('active');
        $('.dropdownmenuHighlight.assetsActive')
          .siblings()
          .removeClass('active');
      } else {
        $('.dropdownmenuHighlight.assetsActive a.item').removeClass('active');
      }

      let parentElement = $('.menu a.item.active')
        .parent()
        .parent();
      $('.menu div.dropdown,.menu div.dropdownmenuHighlight').removeClass(
        'active'
      );
      $(parentElement).addClass('active');

      /*For Update Pages forward and backend button clicks*/
      let urlPathName3 = window.location.pathname.slice(1);
      let arrayMenuButton = $('.dropdown button').toArray();
      let routeNameSplitUpdate = urlPathName3.split('-');
      let updateRoute1 = urlPathName3.split('/'); // For Update Screens

      arrayMenuButton.map((res) => {
        if (res.textContent.toLowerCase() === routeNameSplitUpdate[0]) {
          return $(res)
            .parent()
            .addClass('active');
        } else if (
          updateRoute1[0].split('-')[1] ===
          res.textContent.slice(0, -1).toLowerCase()
        ) {
          $(res)
            .parent()
            .addClass('active');
          $(res)
            .parent()
            .siblings()
            .removeClass('active');
        }
      });

      /*End of Update Pages forward and backend button clicks*/
    };
    if (assetDetailRoute[0] === assetMenu) {
      $('.navbarstyle .menu a.item')
        .first()
        .addClass('active');
      $('.navbarstyle .menu a.item')
        .first()
        .siblings()
        .removeClass('active');
    }

    //For Page Refresh
    arrayMenuButton.map((res) => {
      if (res.textContent.toLowerCase() === routeNameSplit[0]) {
        return $(res)
          .parent()
          .addClass('active');
      } else if (
        updateRoute[0].split('-')[1] ===
        res.textContent.slice(0, -1).toLowerCase()
      ) {
        $(res)
          .parent()
          .addClass('active');
        $(res)
          .parent()
          .siblings()
          .removeClass('active');
      }
    });

    $('.menu a.item')
      .not('.right.menu a.item')
      .on('click', function() {
        $(this).addClass('active');
        $('.dropdown').removeClass('active');
      });
    $('.dropdown-menu a').on('click', function() {
      $(this).removeClass('active');
      $(this)
        .closest('.dropdown ')
        .addClass('active');
      $('.dropdownmenuHighlight a.item').removeClass('active');
    });

    $('.dropdownmenuHighlight').on('click', function() {
      $('.dropdown').removeClass('active');
      $(this).addClass('active');
      $(this)
        .siblings()
        .removeClass('active');
      $(this)
        .siblings()
        .children()
        .removeClass('active');
    });

    $('.dropdown').on('click', function() {
      $('.dropdownmenuHighlight').removeClass('active');
    });
  }
  /* istanbul ignore next */
  handleLogOut = async (event) => {
    localStorage.clear();
    sessionStorage.clear();
  };
  /* istanbul ignore next */
  render() {
    return (
      <div className='navbarstyle'>
        <Menu pointing secondary>
          {JSON.parse(sessionStorage.getItem('permission')) ? (
            <Menu.Menu>
              <Menu.Item className={styles.padding}>
                <img src={logo} alt='Sciex' />
              </Menu.Item>
              <div className='dropdownmenuHighlight assetsActive'>
                <Menu.Item
                  name={NavItems.assets}
                  as={NavLink}
                  exact
                  to='/assets'
                />
              </div>
              <div className='dropdownmenuHighlight onboardingActive'>
                <Menu.Item
                  name={NavItems.onboardingStatus}
                  as={NavLink}
                  exact
                  to='/deviceOnBoarding'
                />
              </div>

              <div className='dropdown'>
                <button data-class='rules' className='' data-toggle='dropdown'>
                  {NavItems.rules}
                  <i aria-hidden='true' className='dropdown icon'></i>
                </button>
                <div className='dropdown-menu'>
                  <Menu.Item
                    name={NavItems.viewList}
                    as={NavLink}
                    exact
                    to='/rules-list'
                  />
                  <Menu.Item
                    name={NavItems.addNew}
                    as={NavLink}
                    exact
                    to='/rules-add-new'
                  />
                  <Menu.Item
                    name={NavItems.importFile}
                    as={NavLink}
                    exact
                    to='/rule-import-file'
                  />
                </div>
              </div>
              <div className='dropdown'>
                <button data-class='users' className='' data-toggle='dropdown'>
                  {NavItems.users}
                  <i aria-hidden='true' className='dropdown icon'></i>
                </button>
                <div className='dropdown-menu'>
                  <Menu.Item
                    name={NavItems.viewList}
                    as={NavLink}
                    exact
                    to='/users-list'
                  />
                  <Menu.Item
                    name={NavItems.addNew}
                    as={NavLink}
                    exact
                    to='/users-add-new'
                  />
                </div>
              </div>
              <div className='dropdown'>
                <button className='' data-toggle='dropdown'>
                  {NavItems.reports}
                  <i aria-hidden='true' className='dropdown icon'></i>
                </button>
                <div className='dropdown-menu'>
                  <Menu.Item
                    name={NavItems.viewList}
                    as={NavLink}
                    exact
                    to='/reports-list'
                  />
                  <Menu.Item
                    className='dropdown-menu'
                    name={NavItems.addNew}
                    as={NavLink}
                    exact
                    to='/reports-add-new'
                  />
                  <Menu.Item
                    name={NavItems.alarmsReport}
                    as={NavLink}
                    exact
                    to='/alarms-report'
                  />
                </div>
              </div>
              <div className='dropdown'>
                <button className='' data-toggle='dropdown'>
                  {NavItems.contents}
                  <i aria-hidden='true' className='dropdown icon'></i>
                </button>
                <div className='dropdown-menu'>
                  <Menu.Item
                    name={NavItems.viewList}
                    as={NavLink}
                    exact
                    to='/contents-list'
                  />
                  <Menu.Item
                    name={NavItems.addNew}
                    as={NavLink}
                    exact
                    to='/contents-add-new'
                  />
                </div>
              </div>
              <div className='dropdown'>
                <button className='' data-toggle='dropdown'>
                  {NavItems.models}
                  <i aria-hidden='true' className='dropdown icon'></i>
                </button>
                <div className='dropdown-menu'>
                  <Menu.Item
                    name={NavItems.viewList}
                    as={NavLink}
                    exact
                    to='/models-list'
                  />
                  <Menu.Item
                    name={NavItems.addNew}
                    as={NavLink}
                    exact
                    to='/models-add-new'
                  />
                </div>
              </div>
              <div className='dropdownmenuHighlight'>
                <Menu.Item
                  name={NavItems.fileUpload}
                  as={NavLink}
                  exact
                  to='/FileUpload'
                />
              </div>
            </Menu.Menu>
          ) : (
            <Menu.Menu>
              <Menu.Item className={styles.padding}>
                <img src={logo} alt='Sciex' />
              </Menu.Item>
              <Menu.Item
                as={NavLink}
                exact
                to='/assets'
                name={NavItems.assets}
              />
              <Menu.Item
                as={NavLink}
                className='onboardingActiveReadOnlyUser'
                exact
                to='/deviceOnBoarding'
                name={NavItems.onboardingStatus}
              />
              <Menu.Item
                as={NavLink}
                exact
                to='/rules-list'
                name={NavItems.rules}
              />
              <Menu.Item
                as={NavLink}
                exact
                to='/users-list'
                name={NavItems.users}
              />
              <Menu.Item
                as={NavLink}
                exact
                to='/reports-list'
                name={NavItems.reports}
              />
              <Menu.Item
                as={NavLink}
                exact
                to='/contents-list'
                name={NavItems.contents}
              />
              <Menu.Item
                as={NavLink}
                exact
                to='/models-list'
                name={NavItems.models}
              />
            </Menu.Menu>
          )}

          <Menu.Menu position='right'>
            <Menu.Item>
              {sessionStorage.getItem('username')}(
              {JSON.parse(sessionStorage.getItem('userInfo')).userGroupsList})
            </Menu.Item>
            <Menu.Item
              name={NavItems.logout}
              onClick={this.handleLogOut}
              href='/'
            />
          </Menu.Menu>
        </Menu>
      </div>
    );
  }
}
/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    email: state.LoginReducer.loginDetailsdata.email,
  };
}
export default connect(mapStateToProps)(NavigationBar);
