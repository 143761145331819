import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import NavigationBar from './components/CommonLayouts/NavigationBar';
import SignIn from './components/Login';
import DeviceDetail from './components/Assets/DeviceDetail/Root';
import Assets from './components/Assets/Root';
import Content from './components/Content/Root';
import Users from './components/Users/Root';
import Models from './components/Models/Root';
import RuleList from './components/RuleList/Root';
import AddNewRule from './components/RuleList/AddNewRule';
import RuleImportFile from './components/RuleList/RuleImportFile';
import AddNewContent from './components/Content/AddNewContent';
import AddNewModel from './components/Models/AddNewModel';
import UpdateModel from './components/Models/UpdateModel';
import Report from './components/Reports/Root';
import { PrivateRoute } from './components/CommonLayouts/ProtectRouteGaurd';
import DeviceOnBoarding from './components/OnboardingStatus/Root';
import ChangePassword from './components/ChangePassword';
import ForgotPassword from './components/ForgotPassword';
import NewPassword from './components/NewPassword';
import CreateUser from './components/Users/AddNewUser';
import UpdateUser from './components/Users/UpdateUser';
import AddNewReport from './components/Reports/AddNewReport/Root';
import AlarmsReport from './components/Alarms/Root';
import NoRightsPage from './components/CommonLayouts/NoRightsPage';
import FileUpload from './components/FileUpload';
const DefaultContainer = () => (
  <>
    <BrowserRouter>
      {sessionStorage.getItem('userInfo') ? <NavigationBar /> : null}
      <Switch>
        <PrivateRoute
          exact
          path='/assets/:id/:model'
          component={DeviceDetail}
          roles={['adminGroup', 'readOnlyUser']}
        />
        <PrivateRoute
          path='/assets'
          component={Assets}
          roles={['adminGroup', 'readOnlyUser']}
        />
        <PrivateRoute
          path='/contents-list'
          component={Content}
          roles={['adminGroup', 'readOnlyUser']}
        />
        <PrivateRoute
          path='/contents-add-new'
          component={AddNewContent}
          roles={['adminGroup']}
        />
        <PrivateRoute
          path='/users-list'
          component={Users}
          roles={['adminGroup', 'readOnlyUser']}
        />
        <PrivateRoute
          exact
          path='/fileUpload'
          component={FileUpload}
          roles={['adminGroup']}
        />
        <PrivateRoute
          exact
          path='/update-rule/:update/:id/:type/:status'
          component={AddNewRule}
          roles={['adminGroup', 'normalGroup']}
        />
        <PrivateRoute
          path='/users-add-new'
          component={CreateUser}
          roles={['adminGroup']}
        />
        <PrivateRoute
          path='/models-list'
          component={Models}
          roles={['adminGroup', 'readOnlyUser']}
        />
        <PrivateRoute
          path='/models-add-new'
          component={AddNewModel}
          roles={['adminGroup']}
        />
        <PrivateRoute
          path='/deviceOnBoarding'
          component={DeviceOnBoarding}
          roles={['adminGroup', 'readOnlyUser']}
        />
        <PrivateRoute
          path='/reports-list'
          component={Report}
          roles={['adminGroup', 'readOnlyUser']}
        />
        <PrivateRoute
          path='/reports-add-new'
          component={AddNewReport}
          roles={['adminGroup']}
        />
        <PrivateRoute
          path='/alarms-report'
          component={AlarmsReport}
          roles={['adminGroup']}
        />
        <PrivateRoute
          path='/update-report/:update/:id'
          component={AddNewReport}
          roles={['adminGroup']}
        />
        <PrivateRoute
          path='/rules-list'
          component={RuleList}
          roles={['adminGroup', 'readOnlyUser']}
        />
        <PrivateRoute
          path='/rules-add-new'
          component={AddNewRule}
          roles={['adminGroup']}
        />
        <PrivateRoute
          path='/rule-import-file'
          component={RuleImportFile}
          roles={['adminGroup']}
        />
        <PrivateRoute
          exact
          path='/update-content/:update/:id'
          component={AddNewContent}
          roles={['adminGroup']}
        />
        <PrivateRoute
          exact
          path='/update-user/:update/:id'
          component={UpdateUser}
          roles={['adminGroup']}
        />
        <PrivateRoute
          exact
          path='/update-model/:update/:id'
          component={UpdateModel}
          roles={['adminGroup']}
        />
        <Route path='*' exact={true} component={NoRightsPage} />
      </Switch>
    </BrowserRouter>
  </>
);

const App = () => {
  return (
    <>
      <div className='App'>
        <BrowserRouter>
          <Switch>
            <Route path='/signin' component={SignIn} />
            <Route exact path='/' component={SignIn} />
            <Route path='/changePassword' component={ChangePassword} />
            <Route path='/forgotPassword' component={ForgotPassword} />
            <Route path='/newPassword' component={NewPassword} />
            <Route component={DefaultContainer} />
          </Switch>
        </BrowserRouter>
      </div>
    </>
  );
};

export default App;
