import { array } from 'prop-types';
import { getDeviceStatusMappings, getICStatus } from '../../utilities/helpers';

export const exportCSVFile = (responseBody) => {
    let dataItems=[];
    for(var data in responseBody.modelsList ){
          dataItems.push(Object.keys(responseBody.modelsList[data].attributes)); 
    }
    
    var dataItemsInfo=[];
    for(var i in responseBody.modelsList){
        const data=dataItems[i];
        
        const individualModel=responseBody.modelsList[i];
        const pureDataItems=[];
        for (var j in data){
        if(data[j]!='updatedTime' && data[j]!='updatedBy' && data[j]!='Description' && data[j]!='modelName' && data[j]!='createdTime' && data[j]!='createdBy' && data[j]!='isDeleted'){
        pureDataItems.push(data[j])
        }
        }
        
        dataItemsInfo[i]=(pureDataItems.map((row,index)=>({
            'DataItem': row,
            'Data Type': individualModel.attributes[row].dataType == undefined || individualModel.attributes[row].dataType ==' ' ? 'N/A' : individualModel.attributes[row].dataType,
            'Display Name':individualModel.attributes[row].displayName,
            'Mnemonic': individualModel.attributes[row].mnemonic 
        })));
        
    }
    var jsonData=[];
    function addUniqeDataItems(data) {
      let index = -1;

      for(let p in jsonData) {
        if(jsonData[p].DataItem === data.DataItem) {
          index = p;
        }
      }

      if(index > -1) {
        jsonData[index] = data;
      } else {
        jsonData.push(data)
      }

    }
    
    for(var i of dataItemsInfo){
        for (var j of i){
            addUniqeDataItems(j);
            
        }
    };
    if (jsonData.length !== 0) {
      const csvData = objectToCsv(jsonData);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'AllModel_Data.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      window.alert('There is No data to Export');
    }
  };

  export const exportCSVModelFile = (modelData,modelsCheckedLists) => {
    let result = Object.values(modelsCheckedLists);
    let data=Object.values(modelData.paramsList)
    const jsonData = result.map((row) => ({
      Model: modelData.modelName,
      Description: modelData.modelDesc,
      'DataItems': row,
      'DisplayName': data[0].attributes[row].displayName,
      'Mnemonic' : data[0].attributes[row].mnemonic,
      'Unit of Measure': data[0].attributes[row].unitsOfMeasure,
      'Data Type' : data[0].attributes[row].dataType==''? 'N/A':data[0].attributes[row].dataType, 
    }));
    if (jsonData.length !== 0) {
      const csvData = objectToCsv(jsonData);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'Model_Data.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      window.alert('There is No data to Export');
    }
  };
  
  export const objectToCsv = (jsonData) => {
    const csvRows = [];
    //get the Headers
    const headers = Object.keys(jsonData[0]);
    csvRows.push(headers.join(','));
    //loop over the rows
    for (const row of jsonData) {
      const values = headers.map((header) => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
  };
  