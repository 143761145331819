import React from "react";
import { Button, Divider } from "semantic-ui-react";
import Form from "react-bootstrap/Form";
import enhance from "./enhance";
import { required } from "../../../utilities/validations";
import { Field } from "redux-form";
import { Dimmer, Loader } from "semantic-ui-react";
import { commonLabels, CreateLabels } from "../../../redux/constants/Values";

export const RuleImportFile=(props)=>{
    const {
        handleSubmit,
        onAttachmentChange,
        isUploading,
        fileName,
        fileError,
        backToListPage,
        btnHandler,
        ruleImportAPIResponseData,
      } = props;
return(
  <>
        {isUploading && (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.uploading}
          </Loader>
        </Dimmer>
      )}
    <div className="containerStyle">
        <h4>
          {CreateLabels.fileUpload}

        <button className="exportButton addDisabled" onClick={btnHandler}>
          {commonLabels.exportToCSV}
        </button>
        </h4>
        <Divider />
        
        <Form className="formStyle"  onSubmit={handleSubmit} > 
            <Form.Group>
            <Form.File
              id="custom-file"
              label={fileName ? fileName : "Upload file"}
              custom
              column="lg"
              variant="success"
              onChange={(e) => {
                onAttachmentChange(e);
              }}
              lg={2}
            />
          </Form.Group>
          <Button.Group>
            <Button type="submit" positive className={fileName ? "" : "addDisabled"}>
              {commonLabels.upload}
            </Button>
            <Button.Or />
                <Button primary onClick={backToListPage}>
                  {commonLabels.back}
                </Button>
          </Button.Group>
          {fileError && (
            <div className="ui red pointing up basic label">{fileError}</div>
          )}
        </Form>
       
    </div>
    </>
);
};

export default enhance(RuleImportFile);