import React from "react";
import enhance from "./enhance";
import { Field } from "redux-form";
import { Form, Button, Divider, Dimmer, Loader } from "semantic-ui-react";
import { InputField, TextAreaField } from "react-semantic-redux-form";
import styles from "./UpdateModel.module.scss";
import { required, limitCharater } from "./../../../utilities/validations";
import { commonLabels, UpdateLabels } from "../../../redux/constants/Values";
import ParamsTableData from "../ModelsTableData/paramsTableData";

export const UpdateModel = (props) => {
  const {
    handleSubmit,
    modelsDeviceParams,
    updateToggleRow,
    isFetching,
    isFetchingDeviceParams,
    fetchUpdateParams,
    updateToggleSelectAll,
    backToListPage,
    modelsChekedLists,
    pageSize,
    btnHandler,
  } = props;

  /* istanbul ignore next */
  return (
    <>
      {isFetching ? (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            Fetching Models Data...
          </Loader>
        </Dimmer>
      ) : (
        <div className="containerStyle">
          <h4>
            {UpdateLabels.models}
            <button className="exportButton" onClick={fetchUpdateParams}>
              {UpdateLabels.reviewChanges}
            </button>
            <button className="refreshButton" onClick={btnHandler} disabled={modelsChekedLists===null? true:false}>
              {commonLabels.exportToCSV}
            </button>
          </h4>
          <Divider />
          <div className="toggleContent">
            <Form onSubmit={handleSubmit}>
              <Form.Group widths="equal">
                <label className={styles.fieldLable}>Model Name</label>
                <Field
                  name="modelName"
                  component={InputField}
                  placeholder="Model Name"
                  validate={[required]}
                  disabled={true}
                />
                <label className={styles.fieldLable}>Description</label>
                <Field
                  name="description"
                  component={TextAreaField}
                  placeholder="Description"
                  cols="50"
                  validate={[required]}
                  normalize={limitCharater}
                />
              </Form.Group>
              <ParamsTableData
                toggleRow={updateToggleRow}
                isFetchingDeviceParams={isFetchingDeviceParams}
                toggleSelectAll={updateToggleSelectAll}
                pageSize={pageSize}
                modelsDeviceParams={modelsDeviceParams}
                modelsChekedLists={modelsChekedLists}
              />
              <Button.Group>
                <Button primary type="submit">
                  {commonLabels.update}
                </Button>
                <Button.Or />
                <Button negative onClick={backToListPage}>
                  {commonLabels.cancel}
                </Button>
              </Button.Group>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default enhance(UpdateModel);
