import React from "react";
import PropTypes from "prop-types";
import "bootstrap";
import enhance from "./enhance";
import { Dimmer, Loader } from "semantic-ui-react";
import { Divider } from "semantic-ui-react";
import ModelsTableData from "./../ModelsTableData";
import { NavItems, commonLabels } from "../../../redux/constants/Values";

export const ModelsList = ({ modelsListsdata,btnHandler }) => {
  return (
    <div className="containerStyle">
      <h4>
        {NavItems.models + " " + commonLabels.list}
        <button className="exportButton" onClick={btnHandler}>
          {commonLabels.exportToCSV}
        </button>
      </h4>
      <Divider />
      {Object.keys(modelsListsdata).length === 0 ? (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      ) : (
        <ModelsTableData modelsListsdata={modelsListsdata} />
      )}
    </div>
  );
};

ModelsList.propTypes = {
  modelsListsdata: PropTypes.object,
};

export default enhance(ModelsList);
