// export const bucketName = {
//   BUCKET: "device-params-dev", // helps to switch bucket name for PREPROD and PROD
//   // PREPROD : "device-params", "PROD": "statusscopesystemmanagementbucket", DEV: "device-params-dev"
// };

export const pageSize = {
  SIZE: 10000,
};

export const bucketName = {
  bucketName: 'rules-update-dev',
};

export const commonLabels = {
  advanceSearch: 'Advance Search',
  search: 'Search',
  filter: 'Filter',
  clear: 'Clear',
  upload: 'Upload',
  list: 'List',
  exportToCSV: 'Export to CSV',
  data: 'Data',
  viewAll: 'View All',
  back: 'Back',
  asset: 'Asset',
  loading: 'Page is Loading...',
  fetching: 'Fetching Data...',
  next: 'Next',
  cancel: 'Cancel',
  update: 'Update',
  finish: 'Finish',
  save: 'Save',
  add: 'Add',
  delete: 'Delete',
  noContent: 'No Content',
  noDeviceParams: 'No Device Params for the Model selected !!',
  instructions: 'INSTRUCTIONS',
  textarea: 'text-area',
  example: 'ex: (A>B).',
  validateExp: 'Validate Expression',
  clearExp: 'Clear Expression',
  addRow: 'Add row',
  uploading: 'Uploading...',
  download: 'Download',
  execute: 'Execute',
  disable: 'Disable',
  enable: 'Enable',
  passwordChange: 'Force Password Change',
  historicalDataitems: 'Historical Dataitem Values',
  refresh: 'Refresh',
  fetchlatestdata: 'Fetch Latest Data',
};

export const NavItems = {
  assets: 'Assets',
  onboardingStatus: 'On Boarding Status',
  rules: 'Rules',
  users: 'Users',
  reports: 'Reports',
  contents: 'Contents',
  models: 'Models',
  fileUpload: 'File Upload',
  logout: 'Logout',
  viewList: 'View List',
  addNew: 'Add New',
  importFile: 'Import File',
  alarmsReport: 'Alarms Report',
};

export const Grids = {
  assetDetails: 'Asset Details',
  alarms: 'Alarms',
  massSpec: 'Mass Spec',
  sampleItem: 'Sample Queue Events',
  rules: 'Rules',
  workstation: 'WorkStation',
  lc: 'LC',
  error: 'Error',
  historicalDataitems: 'Historical Dataitems',
};

export const CreateLabels = {
  dataExpression: 'Create Rule for Data Expression',
  deviceCode: 'Create Rule for Device Code',
  rulesOfRules: 'Create Rules of Rules',
  modelsForRules: 'Select Models for Rules',
  configureRules: 'Configure Rules',
  configureSeverity: 'Configure Severity',
  immediateSeverity: 'Send Immediately',
  nonImmediateSeverity: 'Send Every 24 Hours',
  weeklyOnceSeverity: 'Send Every 7 Days',
  monthlyOnceSeverity: 'Send Every 30 Days',
  addDeviceCode: 'Add Device Code',
  rulesList: 'List of Rules',
  users: 'Create User',
  reports: 'Add New Report',
  contents: 'Create Content',
  models: 'Create Model',
  constructQuery: 'Construct Query',
  submitQuery: 'Submit Query',
  addParameter: 'Add Parameter Condition',
  fileUpload: 'Upload Data File',
  changePassword: 'Change Password',
  historyData: 'View history data',
  login: 'Login',
  forgotPassword: 'Forgot Password ?',
  backLogin: 'Back to Login ?',
  submit: 'Submit',
};

export const UpdateLabels = {
  dataExpression: 'Update Rule for Data Expression',
  deviceCode: 'Update Rule for Device Code',
  rulesOfRules: 'Update Rules of Rules',
  users: 'Update User',
  reports: 'Update Report',
  contents: 'Update Content',
  models: 'Update Model',
  reviewChanges: 'Review Changes From Excel',
};

export const Alerts = {
  dateRange: 'Start Date should be earlier or equal To End Date !!',
  validDate: 'Date should be earlier or equal To Today Date !!',
  invalidDate:
    'Please enter a valid value. The field is incomplete or has an invalid date !!',
  mandatoryValidation:
    '(Note: Please validate expression in order to proceed next)',
  ruleInstruction1: 'Expression should enclose with braces',
  ruleInstruction2: 'Only braces ( and ) are permitted in',
  ruleInstruction3:
    'Backspace is enabled for removing expression (use it carefully)',
  ruleInstruction4: 'Use control + z to undo the changes.',
  ruleInstruction5:
    "Don't remove quotes it will change the definition of rule.",
  validExpression: 'Expression validated successfully',
  invalidExpression: 'Invalid Expression',
  mandatoryRules: "(Note: It's mandatory to select 2 Rules to Proceed)",
  sessionOut: 'Session Timed out, Please Login',
  invalidId: 'Invalid User Name or Password',
  passwordMismatch: 'Conform password is not matching with New password',
  passwordRequired:
    'Password Should Contain 1 Uppercase, 1 Smallercase & 1 Special Character with minimum 10 characters',
  invalidFile: 'Invalid file type',
  selectFileType: 'Please select type of File to Upload !!',
  uploadError: 'Something went wrong. please try again !!',
  inputDate: 'Please provide Valid Date !!',
  mandatoryError: 'Please fill all Mandatory fields !!',
  maxRules: 'Only 2 Rules can be Selected at a time !!',
  fetchError: 'Failed to fetch the data !!',
  selectDevice: 'Please select the Device to Update !!',
  validClause: 'Provide valid Where Clause',
  noDataitem: 'Please select atleast one dataitem',
  noData: 'No Data available for the Model !!',
  noRows: 'No Rows Found',
  deviceCodeName:
    "Note: (Device Code allows HexaDecimal Values.It should start with '0x')",
  validWhereClause: 'Please provide valid where caluse',
};
