import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers } from 'recompose';
import { reduxForm, reset } from 'redux-form';
import {
  resetHistoricalDataitemsData,
  detailsPageBackButtonIsClicked,
  fetchDeviceParameters,
  fetchHistoricalDataitems,
} from '../../../../redux/reducers/AssetListReducer';
import { withRouter } from 'react-router';
import moment from 'moment/min/moment-with-locales';
import { Alerts } from '../../../../redux/constants/Values';
import { exportCSVForHistoricalDataitems } from '../../../../apis/assetListAPI/normalizers';
import types from '../../../../redux/actions/actionTypes';

export const mapStateToProps = (state) => ({
  isFetchingHistoricalDataitemsData:
    state.assetListReducer.isFetchingHistoricalDataitemsData,
  historicalDataitemsDetailsData:
    state.assetListReducer.historicalDataitemsDetailsData,
  
});

/* istanbul ignore next */
export const isDetailsPageBackButtonIsClicked = ({
  detailsPageBackButtonIsClicked,
}) => () => {
  detailsPageBackButtonIsClicked();
};

/* istanbul ignore next */
export const exportFile = () => {
  exportCSVForHistoricalDataitems();
};

/* istanbul ignore next */
export const onSubmit = ({ fetchHistoricalDataitems }) => async (
  values,
  dispatch,
  props
) => {
  let mnemonics = [];
  let Todaydate = moment(new Date().setHours(23, 59, 0, 0))
    .add(1, 'days')
    .utc()
    .format();
  mnemonics =
    values.dataitem !== undefined && values.dataitem.length !== 0
      ? values.dataitem.map((item) => {
          return item.value;
        })
      : [];
  
  if (Object.keys(values).length !== 0) {
    if (
      values.hasOwnProperty('startDate') &&
      values.hasOwnProperty('endDate') &&
      values.hasOwnProperty('dataitem')
    ) {
      let data = {
        startDate: moment(
          values.startDate + values.startTime,
          'YYYY-MM-DDTHH:mm:s'
        )
        .format('YYYY-MM-DDTHH:mm:ss[Z]'),
        endDate: moment(values.endDate + values.endTime, 'YYYY-MM-DDTHH:mm:s')
          .format('YYYY-MM-DDTHH:mm:ss[Z]'),
        mnemonics: [], // Initialize mnemonics as empty
        deviceName: props.deviceName,
      };

      dispatch({ type: types.RESET_HISTORICAL_DATAITEMS_DATA });
      // Chunk the mnemonics into smaller arrays of size 25
      const chunkSize = 25;
      const mnemonicChunks = [];
      for (let i = 0; i < mnemonics.length; i += chunkSize) {
        mnemonicChunks.push(mnemonics.slice(i, i + chunkSize));
      }

      // Loop through each chunk and send it
      if (data.startDate <= Todaydate) {
        if (data.startDate <= data.endDate) {
          mnemonicChunks.forEach((chunk) => {
            // Update the data object for each chunk
            data.mnemonics = chunk;
            fetchHistoricalDataitems(data);
          });
        } else {
          window.alert(Alerts.dateRange);
        }
      } else {
        window.alert(Alerts.validDate);
      }
    } else if (values.dataitem === undefined || values.dataitem.length === 0) {
      window.alert(Alerts.noDataitem);
    } else {
      window.alert(Alerts.invalidDate);
    }
  } else {
    window.alert(Alerts.invalidDate);
  }
};


export const getHistoricalDataitems = ({
  fetchHistoricalDataitems,
  resetHistoricalDataitemsData,
  reset,
}) => (props) => {
  let data = {
    mnemonics: [],
    deviceName: props.deviceName,
  };
  fetchHistoricalDataitems(data);
  resetHistoricalDataitemsData();
  reset('SearchHistoricalDataitemsForm');
};

export function componentWillUnmount() {
  this.props.resetHistoricalDataitemsData();
}

export default compose(
  withRouter,
  connect(mapStateToProps, {
    resetHistoricalDataitemsData,
    reset,
    detailsPageBackButtonIsClicked,
    fetchDeviceParameters,
    fetchHistoricalDataitems,
  }),
  lifecycle({ componentWillUnmount }),
  withHandlers({
    onSubmit,
    getHistoricalDataitems,
    isDetailsPageBackButtonIsClicked,
    exportFile,
  }),
  reduxForm({
    form: 'SearchHistoricalDataitemsForm',
    enableReinitialize: true,
  })
);
