import React, { useState, useEffect } from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

export const RenderSelectInput = ({
  input,
  options,
  disabled,
  meta: { error, submitFailed },
}) => {
  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      console.log(this.options);
      input.onChange(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      input.onChange([]);
    } else if (event.action === "deselect-option") {
      input.onChange(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      input.onChange(this.options);
    } else {
      input.onChange(value);
    }
  }


  

  return (
    <div className="multiCheckBox">
      <ReactMultiSelectCheckboxes
        {...input}
        options={[{ label: "All", value: "*" }, ...options]}
        value={input.value}
        onChange={onChange}
        onBlur={() => input.onBlur()}
        meta={error}
        isDisabled={disabled}
        isClearable={true}
        
      />
      {submitFailed &&
        !disabled &&
        (input.value.length === 0 || input.value === '') && (
          <div className="ui red pointing basic label">Required</div>
        )}
    </div>
  );
};

export default RenderSelectInput;
