import React, { useState } from 'react';
import { Card, Form } from 'semantic-ui-react';
import { Field } from 'redux-form';
import { required } from './../../../../utilities/validations';
import RadioGroup from './../../RadioGroup/index';
import styles from './DeviceCodeActionsForCard.module.scss';
import { CreateLabels } from '../../../../redux/constants/Values';

export const DeviceCodeSeverityForCard = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const onChangeValue = (event) => {
    setSelectedOption(event.target.value);
    console.log(event.target.value);
  }
  return (
    <div className='cards'>
      <Card className={styles.deviceCustomWidthFix}>
        <Card.Content
          header={CreateLabels.configureSeverity}
          className='cardHeader'
        />

        <Card.Content extra>
          <Form.Field>
            <Field
              component={RadioGroup}
              name="NotificationType"
              validate={[required]}
              onChange={onChangeValue}
              options={[
                { title: "No Notification", value: "No Notification" },
                { title: "Customer", value: "Customer" },
                { title: "Tester", value: "Tester" },
                { title: "TAC", value: "TAC Only" },
                { title: "Customer & TAC", value: "Customer & TAC" },
              ]}
            />
          </Form.Field>

          {selectedOption.length !== 0 ?
            (selectedOption === "Customer" && <>
              <Card.Content
                header="Notification Frequency"
                className={styles.cardSubHeader}
              />
              <Form.Field>
                <Field
                  component={RadioGroup}
                  name="severity"
                  validate={[required]}
                  options={[
                    { title: CreateLabels.immediateSeverity + "(121)", value: "121" },
                    { title: CreateLabels.nonImmediateSeverity + "(120)", value: "120" },
                    { title: CreateLabels.weeklyOnceSeverity + "(122)", value: "122" },
                    { title: CreateLabels.monthlyOnceSeverity + "(123)", value: "123" },
                  ]}
                />
              </Form.Field>
            </>
            ) || (selectedOption === "Tester" && <>
              <Card.Content
                header="Notification Frequency"
                className={styles.cardSubHeader}
              />
              <Form.Field>
                <Field
                  component={RadioGroup}
                  name="severity"
                  validate={[required]}
                  options={[
                    { title: CreateLabels.immediateSeverity + "(161)", value: "161" },
                    { title: CreateLabels.nonImmediateSeverity + "(160)", value: "160" },
                    { title: CreateLabels.weeklyOnceSeverity + "(162)", value: "162" },
                    { title: CreateLabels.monthlyOnceSeverity + "(163)", value: "163" },
                  ]}
                />
              </Form.Field>
            </>
            ) || (selectedOption === "Customer & TAC" && <>
              <Card.Content
                header="Notification Frequency"
                className={styles.cardSubHeader}
              />
              <Form.Field>
                <Field
                  component={RadioGroup}
                  name="severity"
                  validate={[required]}
                  options={[
                    { title: CreateLabels.immediateSeverity + "(181)", value: "181" },
                    { title: CreateLabels.nonImmediateSeverity + "(180)", value: "180" },
                    { title: CreateLabels.weeklyOnceSeverity + "(182)", value: "182" },
                    { title: CreateLabels.monthlyOnceSeverity + "(183)", value: "183" },
                  ]}
                />
              </Form.Field>
            </>
            ) || (selectedOption === "No Notification" && <>
              <Card.Content
                header="Notification Frequency"
                className={styles.cardSubHeader}
              />
              <Form.Field>
                <Field
                  component={RadioGroup}
                  name="severity"
                  validate={[required]}
                  options={[
                    { title: "No Notificatiion to any" + "(100)", value: "100" },
                  ]}
                />
              </Form.Field>
            </>
            ) || (selectedOption === "TAC Only" && <>
              <Card.Content
                header="Notification Frequency"
                className={styles.cardSubHeader}
              />
              <Form.Field>
                <Field
                  component={RadioGroup}
                  name="severity"
                  validate={[required]}
                  options={[
                    { title: CreateLabels.immediateSeverity+"(171)" , value: "171" },
                    { title: CreateLabels.nonImmediateSeverity+"(170)", value: "170" },
                    { title: CreateLabels.weeklyOnceSeverity+"(172)", value: "172" },
                    {title: CreateLabels.monthlyOnceSeverity+"(173)", value: "173"},
                  ]}
                />
              </Form.Field>
            </>
            ) : <div>
            </div>}
        </Card.Content>
      </Card>
    </div>
  );
};
export default DeviceCodeSeverityForCard;
