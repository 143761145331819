import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers } from 'recompose';
import { reduxForm, reset } from 'redux-form';
import {
  resetHistoryData,
  fetchHistoryData,
} from '../../../../../redux/reducers/AssetListReducer';
import moment from 'moment/min/moment-with-locales';
import { Alerts } from '../../../../../redux/constants/Values';
import { exportCSVForHistoryData } from '../../../../../apis/assetListAPI/normalizers';

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  isFetchingHistoryData: state.assetListReducer.isFetchingHistoryData,
  selectedMnemonic: state.assetListReducer.selectedMnemonic,
  tableType: state.assetListReducer.tableType,
  historyDetailsData: state.assetListReducer.historyDetailsData,
  initialValues: {
    startDate: moment(new Date())
      .add(-1, 'days')
      .format('YYYY-MM-DD'),
    endDate: moment(new Date()).format('YYYY-MM-DD'),
  },
});

/* istanbul ignore next */
export const exportFile = ({ tableType }) => {
  exportCSVForHistoryData(tableType);
};

/**
 *
 * @description {submit values}
 */
export const onSubmit = ({ fetchHistoryData }) => async (
  values,
  dispatch,
  props
) => {
  let Todaydate = moment(new Date().setHours(23, 59, 0, 0))
    .add(1, 'days')
    .utc()
    .format();
  if (Object.keys(values).length !== 0) {
    if (
      values.hasOwnProperty('startDate') &&
      values.hasOwnProperty('endDate')
    ) {
      let data = {
        startDate: moment(
          values.startDate + values.startTime,
          'YYYY-MM-DDTHH:mm:s'
        )
          .format('YYYY-MM-DDTHH:mm:ss[Z]'),
        endDate: moment(values.endDate + values.endTime, 'YYYY-MM-DDTHH:mm:s')
          .format('YYYY-MM-DDTHH:mm:ss[Z]'),
        name: props.selectedMnemonic,
        id: props.id,
        category: props.category,
      };
      // if (data.startDate <= Todaydate && data.endDate <= Todaydate) {
      if (data.startDate <= Todaydate) {
        if (data.startDate <= data.endDate) {
          fetchHistoryData(data);
        } else {
          window.alert(Alerts.dateRange);
        }
      } else {
        window.alert(Alerts.validDate);
      }
    } else {
      window.alert(Alerts.invalidDate);
    }
  } else {
    window.alert(Alerts.invalidDate);
  }
};

/**
 *
 * @description {get history values}
 */
export const getHistoryData = ({ fetchHistoryData, reset }) => (values) => {
  let data = {
    name: values.name,
    id: values.id,
    category: values.category,
  };
  fetchHistoryData(data);
  reset('SearchHistoryDataForm');
};

/**
 *
 * @description {remove/unmount component values}
 */
export function componentWillUnmount() {
  this.props.resetHistoryData();
}

export default compose(
  connect(mapStateToProps, { resetHistoryData, fetchHistoryData, reset }),
  lifecycle({ componentWillUnmount }),
  withHandlers({
    onSubmit,
    getHistoryData,
    exportFile,
  }),
  reduxForm({
    form: 'SearchHistoryDataForm',
    enableReinitialize: true,
  })
);
