import raiseError from "../../utilities/raiseError";
import api from "../config_api";
import { bucketName } from "../../redux/constants/Values";
import { authHeader } from "../authAPI/loginAPI";
import { getHeaders } from "../../utilities/helpers";

export const uploadFilePreSignedUrl = async ({ getState, values }) => {
  const getFile = getState().RuleImportFileReducer.uploadFileData;
  let reqPostObj = {
    fileKey: getState().RuleImportFileReducer.uploadFileData.name,
  };
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(reqPostObj),
  };
  if (reqPostObj.fileKey === undefined) {
    return { Message: "Invalid file type" };
  } else {
    const response = await fetch(
        api.uploadruledata_generatepressignedurl,
      requestOptions
    );
    let responseBody;
    if (response.ok) {
      responseBody = await response.json();
      return uploadFile(responseBody, getFile, values, getState);
    } else {
      responseBody = await response.json();
      raiseError({ response, responseBody });
    }
  }
};

export const uploadFile = async (responseBody2, getFile2, values, getState) => {
  const requestOptions2 = {
    method: "PUT",
    headers: {
      "Content-Type": "application/octet-stream",
      AccessToken: authHeader(),
    },
    body: getFile2,
  };
  const upload_putUrl = responseBody2.putUrl;

  const response = await fetch(upload_putUrl, requestOptions2);
  let responseBody;
  if (response.ok) {
    return uploadFile3(values, getState);
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const uploadFile3 = async (values, getState) => {
  let deviceParamsObject = {
    // bucketName: bucketName.BUCKET,
    fileName: getState().RuleImportFileReducer.uploadFileData.name,
    bucketName: bucketName.bucketName,
  };
  const requestOptions3 = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(deviceParamsObject),
  };
  const responseThree = await fetch(
    api.uploadruledata,
    requestOptions3
  );
  let responseBody3new;
  if (responseThree.ok) {
    responseBody3new = await responseThree.json();
    return responseBody3new;
  } else {
    if(responseThree.status===400){
      responseBody3new = await responseThree.json();
    return responseBody3new;
    }
    return responseThree.json().then((text) => {
      throw new Error(text.message);
    });
  }
};
