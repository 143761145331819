import React from "react";
import PropTypes from "prop-types";
import "bootstrap";
import AdvanceSearch from "../AdvanceSearch";
import RulesTableData from "./../RulesTableData";
import { Divider } from "semantic-ui-react";
import enhance from "./enhance";
import { NavItems, commonLabels } from "../../../redux/constants/Values";

export const RuleList = (props) => {
  const { btnHandler, rulesListsdata, routeChange } = props;

  return (
    <div className="containerStyle">
      <h4>
        {NavItems.rules + " " + commonLabels.list}
        <button className="exportButton" onClick={btnHandler}>
          {commonLabels.exportToCSV}
        </button>
        <button className="refreshButton" onClick={routeChange}>
          Import file
        </button>
      </h4>
      <Divider />
      <AdvanceSearch />
      <Divider />
      <RulesTableData rulesListsdata={rulesListsdata} />
    </div>
  );
};

RuleList.propTypes = {
  rulesListsdata: PropTypes.array,
};

export default enhance(RuleList);
