import types from "../actions/actionTypes";
import { createThunk } from "../../utilities/createThunk";
import backendAPI from "../../apis";
import { combineReducers } from "redux";

const initialState = {
  uploadFileContent: "",
  uploadFile: "",
  isUploadingFile: false,
  ruleImportAPIResponseData: [],
};
export const fetchUploadFileContent = (values) => ({
  type: types.UPLOAD_FILE_CONTENT,
  payload: values,
});

export const resetUploadFileContent = (values) => ({
  type: types.RESET_UPLOAD_FILE_CONTENT,
  payload: values,
});

export const fetchUploadFile = createThunk({
  requestActionType: types.FETCH_UPLOAD_FILE_REQUEST,
  apiCall: backendAPI.ruleImportFileAPI.uploadFilePreSignedUrl,
});

const uploadFile = (state = initialState.uploadFile, { type, payload }) => {
  switch (type) {
    case types.FETCH_UPLOAD_FILE_REQUEST_SUCCESS:
    case types.FETCH_UPLOAD_FILE_REQUEST_FAILURE:
      return payload;
    case types.RESET_FILE_UPLOAD_PAGE:
      return initialState.uploadFile;
    default:
      return state;
  }
};

const ruleImportAPIResponseData = (
  state = initialState.ruleImportAPIResponseData, { type, payload }) => {
  switch (type) {
    case types.FETCH_UPLOAD_FILE_REQUEST_SUCCESS:
    case types.FETCH_UPLOAD_FILE_REQUEST_FAILURE:
      return payload;
    case types.RESET_FILE_UPLOAD_PAGE:
      return initialState.ruleImportAPIResponseData;
    default:
      return state;
  }
};

const uploadFileData = (
  state = initialState.uploadFileContent,
  { type, payload }
) => {
  switch (type) {
    case types.UPLOAD_FILE_CONTENT:
      return payload;
    case types.RESET_UPLOAD_FILE_CONTENT:
      return payload;
    case types.RESET_FILE_UPLOAD_PAGE:
      return initialState.uploadFileContent;
    default:
      return state;
  }
};

const isUploadingFile = (
  state = initialState.isUploadingFile,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_UPLOAD_FILE_REQUEST:
      return true;
    case types.FETCH_UPLOAD_FILE_REQUEST_SUCCESS:
      return false;
    case types.FETCH_UPLOAD_FILE_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};


export const RuleImportFileReducer = {
  uploadFileData,
};

export const resetFileUploadPage = () => ({
  type: types.RESET_FILE_UPLOAD_PAGE,
});

export default combineReducers({
  uploadFileData,
  uploadFile,
  isUploadingFile,
  ruleImportAPIResponseData,
});
